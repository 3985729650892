import {Component} from 'react'
import { generateClient } from 'aws-amplify/api';
import { listPosts } from '../graphql/queries'
import { Link } from 'react-router-dom';
import {
  View,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@aws-amplify/ui-react';
import Header from '../components/Header';

class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      allPosts: []
    }

    this.getAllPosts = this.getAllPosts.bind(this);
  }

  async getAllPosts() {
    const client = generateClient();
    const result = await client.graphql({
      query: listPosts,
      variables: {
        username: this.state.username
      },
      // 認可の参考記事
      // https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/customize-authorization-rules/
      authMode: 'userPool'
    });

    // 週で降順に表示する
    let sortedPostList = result.data.listPosts.items;
    sortedPostList.sort((a, b) => {
      if (a.times > b.times) {
        return -1;
      }
      if (a.times < b.times) {
        return 1;
      }
      return 0;
    });

    this.setState({allPosts: sortedPostList});
  }

  componentDidMount() {
    this.getAllPosts();
  }

  render() {
    return (
      <View>
        <Header/>
        <h1>投稿一覧</h1>
        <Link to="/new_post">
          <Button variation="primary" onClick={this.addPost}>新規投稿</Button>
        </Link>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell as="th">週</TableCell>
              <TableCell as="th">強化項目</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.state.allPosts.map((row) => (
              <TableRow key={ row.times }>
                <TableCell>
                  <Link to={`/edit/${row.times}`}>
                    { row.times }
                  </Link>
                  </TableCell>
                  <TableCell
                    text-overflow="ellipsis"
                    white-space="nowrap"
                    overflow="hidden"
                    max-width="0"
                  >
                    <Link to={`/edit/${row.times}`}>
                      { row.lastWeekTodo }
                    </Link>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </View>
    )
  }
}

export default PostList;