import {Component} from 'react'
import {
  Menu,
  MenuItem,
} from "@aws-amplify/ui-react";
import {
  Link
} from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <Menu
        menuAlign="start"
        size="large"
      >
        <MenuItem>
        <Link to="/">投稿一覧</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/new_post">新規投稿</Link>
        </MenuItem>
        <MenuItem onClick={() => alert('菊池のNote')}>
          菊池のNote
        </MenuItem>
        <MenuItem onClick={() => alert('お問い合わせ')}>
          お問い合わせ
        </MenuItem>
        <MenuItem onClick={() => alert('使い方')}>
          使い方
        </MenuItem>
        <MenuItem onClick={() => alert('ログアウト')}>
          ログアウト
        </MenuItem>
      </Menu>
    )
  }
}

export default Header;