import React from 'react'
import { Amplify } from 'aws-amplify'
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import {
  Authenticator,
//   View
} from '@aws-amplify/ui-react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import PostList from './pages/PostList';
import NewPost from './pages/NewPost';
import Edit from './pages/Edit';

Amplify.configure(awsExports);

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<PostList username={user.username}/>} />
            <Route path="/new_post" element={<NewPost username={user.username}/>} />
            <Route path="/edit/:times" element={<Edit username={user.username}/>} />
          </Routes>
        </BrowserRouter>
      )}
    </Authenticator>
  );
}

export default App;
