import {Component} from 'react'
import {
  Grid,
  View,
  Flex,
  Text,
  TextField,
  TextAreaField,
  Button
} from '@aws-amplify/ui-react';
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../graphql/mutations';
import { getPost } from '../graphql/queries'
import Header from '../components/Header';
import { useParams } from 'react-router-dom';

// pathparameter付き画面遷移の参考記事
// https://stackoverflow.com/questions/58548767/react-router-dom-useparams-inside-class-component
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      times: props.params.times,
      lastWeekTodo: "",
      thisWeekTry: "",
      thisWeekFeedback: "",
      nextWeekTodo: "",
      usualMenu: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.getPost = this.getPost.bind(this);
    this.updatePost = this.updatePost.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async getPost() {
    const client = generateClient();
    const result = await client.graphql({
      query: getPost,
      variables: {
        username: this.state.username,
        times: this.state.times
      },
      // 認可の参考記事
      // https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/customize-authorization-rules/
      authMode: 'userPool'
    });
    this.setState({lastWeekTodo: result.data.getPost.lastWeekTodo});
    this.setState({thisWeekTry: result.data.getPost.thisWeekTry});
    this.setState({thisWeekFeedback: result.data.getPost.thisWeekFeedback});
    this.setState({nextWeekTodo: result.data.getPost.nextWeekTodo});
    this.setState({usualMenu: result.data.getPost.usualMenu});
  }

  async updatePost() {
    const client = generateClient();
    await client.graphql({
      query: mutations.updatePost,
      variables: {
        username: this.state.username,
        times: this.state.times,
        lastWeekTodo: this.state.lastWeekTodo,
        thisWeekTry: this.state.thisWeekTry,
        thisWeekFeedback: this.state.thisWeekFeedback,
        nextWeekTodo: this.state.nextWeekTodo,
        usualMenu: this.state.usualMenu
      },
      // 認可の参考記事
      // https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/customize-authorization-rules/
      authMode: 'userPool'
    }).catch((err) => {
      alert("時間を置いて再度お試しください")
    });
    // 画面遷移時に再レンダリングが必要
    // https://dev.classmethod.jp/articles/fix-the-wrong-implementation-of-page-transitions-in-react-app/
    window.location.href = "/"
  }

  componentDidMount() {
    this.getPost();
  }

  render() {
    return (
      <View>
        <Header/>
        <Grid as="form" rowGap="15px" columnGap="15px" padding="20px">
          <Flex>
            <TextField
              name="times"
              readOnly="readonly"
              width="25%"
              value={this.state.times}
            /><Text>週目</Text>
          </Flex>
          <TextAreaField
            label="前週の最後に決めた強化項目"
            name="lastWeekTodo"
            readOnly="readonly"
            onChange={this.handleChange}
            value={this.state.lastWeekTodo}
          ></TextAreaField>
          <TextAreaField
            label="今週試したこと"
            name="thisWeekTry"
            readOnly="readonly"
            onChange={this.handleChange}
            value={this.state.thisWeekTry}
            placeholder="実績やマッチしたい人のイメージ、得意な領域などを記載してください。"
          ></TextAreaField>
          <TextAreaField
            label="今週の気付き"
            name="thisWeekFeedback"
            readOnly="readonly"
            onChange={this.handleChange}
            value={this.state.thisWeekFeedback}
            placeholder="実績やマッチしたい人のイメージ、得意な領域などを記載してください。"
          ></TextAreaField>
          <TextAreaField
            label="来週強化したいこと（1つだけ）"
            name="nextWeekTodo"
            readOnly="readonly"
            onChange={this.handleChange}
            value={this.state.nextWeekTodo}
            placeholder="サーブやストロークの強化、ネットへの出方を洗練させる、など。"
          ></TextAreaField>
          <TextAreaField
            label="いつものトレーニング"
            name="usualMenu"
            readOnly="readonly"
            onChange={this.handleChange}
            value={this.state.usualMenu}
            placeholder="サーブやストロークの強化、ネットへの出方を洗練させる、など。"
          ></TextAreaField>
          <Button variation="primary" onClick={this.updatePost}>更新する</Button>
        </Grid>
      </View>
    )
  }
}

export default withParams(Edit);